import { NgClass, NgStyle } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, signal, Component, ChangeDetectionStrategy, Input, Output, HostBinding, HostListener, Pipe, NgModule } from '@angular/core';
import { crmKillEvent, crmResolveExpression, CrmElementIdPipe, CrmDisabledDirective, CrmUnsubscribeDirective, CrmMergeObjectsPipe } from 'common-module/core';
import { CrmRendererDirective, CrmIsRenderItemPipe } from 'common-module/renderer';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { Subject, race, combineLatest, map, of, startWith } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { NzDropDownDirective, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';

/**
 * Action buttons set that render given header action item as button
 * or as plain a button item e.g. for dropdown lists.
 */
function CrmActionButtonComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
  }
  if (rf & 2) {
    i0.ɵɵproperty("crmRenderer", ctx);
  }
}
function CrmActionButtonComponent_Conditional_2_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("nzType", ctx_r0.action.icon);
  }
}
function CrmActionButtonComponent_Conditional_2_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r0.action.title));
  }
}
function CrmActionButtonComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵpipe(3, "crmTranslate");
    i0.ɵɵtemplate(4, CrmActionButtonComponent_Conditional_2_Conditional_4_Template, 1, 1, "i", 2)(5, CrmActionButtonComponent_Conditional_2_Conditional_5_Template, 3, 3, "span");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_4_0;
    let tmp_6_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 13, ctx_r0.action.id, "action-button"))("ngClass", ctx_r0.action.className)("ngStyle", ctx_r0.action.style)("nzType", (tmp_4_0 = ctx_r0.action.type) !== null && tmp_4_0 !== undefined ? tmp_4_0 : "default")("nzDanger", ctx_r0.action.danger)("nzSize", (tmp_6_0 = ctx_r0.action.size) !== null && tmp_6_0 !== undefined ? tmp_6_0 : "large")("nzBlock", ctx_r0.action.block)("isDisabled", ctx_r0.isDisabled())("disabledTooltip", i0.ɵɵpipeBind1(2, 16, ctx_r0.action.disabledTooltip))("enabledTooltip", i0.ɵɵpipeBind1(3, 18, ctx_r0.action.tooltip))("nzLoading", ctx_r0.isLoading());
    i0.ɵɵadvance(4);
    i0.ɵɵconditional(4, ctx_r0.action.icon ? 4 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(5, ctx_r0.action.title ? 5 : -1);
  }
}
const _forTrack0 = ($index, $item) => $item.id;
const _c0 = () => ({});
const _c1 = () => ({
  type: "link",
  size: "small"
});
function CrmDropdownButtonComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 2);
  }
  if (rf & 2) {
    let tmp_3_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzType", ctx_r1.moreIcon)("nzRotate", (tmp_3_0 = ctx_r1.moreIconRotate) !== null && tmp_3_0 !== undefined ? tmp_3_0 : 0);
  }
}
function CrmDropdownButtonComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(1, 1, ctx_r1.moreText), " ");
  }
}
function CrmDropdownButtonComponent_For_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 4);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵelementStart(2, "crm-action-button", 5);
    i0.ɵɵpipe(3, "crmMergeObjects");
    i0.ɵɵlistener("actionClicked", function CrmDropdownButtonComponent_For_8_Template_crm_action_button_actionClicked_2_listener() {
      const action_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick(action_r4));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const action_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 3, action_r4.id, "detailHeader_actions"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("data", ctx_r1.data)("action", i0.ɵɵpipeBind2(3, 6, i0.ɵɵpureFunction0(9, _c1), action_r4));
  }
}
function CrmActionButtonsComponent_For_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "crm-action-button", 2);
    i0.ɵɵlistener("actionClicked", function CrmActionButtonsComponent_For_1_Template_crm_action_button_actionClicked_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const action_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", action_r3.actionStyle)("action", action_r3)("data", ctx_r1.data);
  }
}
function CrmActionButtonsComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "crm-dropdown-button", 3);
    i0.ɵɵpipe(1, "crmElementId");
    i0.ɵɵlistener("actionClicked", function CrmActionButtonsComponent_Conditional_2_Template_crm_dropdown_button_actionClicked_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.handleActionClick($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_12_0;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", i0.ɵɵpipeBind2(1, 12, "more_dropdown", "action-buttons"))("actions", ctx_r1.dropdownActions())("moreText", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.moreText)("moreIcon", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.moreIcon)("moreIconRotate", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.moreIconRotate)("isDisabled", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.isDisabled)("disabledTooltip", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.disabledTooltip)("type", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.type)("size", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.size)("dropdownTrigger", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.dropdownTrigger)("buttonStyle", ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.buttonStyle)("data", (tmp_12_0 = ctx_r1.dropdownConfig == null ? null : ctx_r1.dropdownConfig.data) !== null && tmp_12_0 !== undefined ? tmp_12_0 : ctx_r1.data);
  }
}
class CrmActionButtonComponent {
  constructor() {
    this.actionClicked = new EventEmitter();
    this.hostClass = 'crm-action-button';
    this.isDisabled = signal(false);
    this.isLoading = signal(false);
    this.reload$ = new Subject();
  }
  ngOnChanges(changes) {
    this.handleActionChange(changes['action']);
  }
  handleActionClick(event) {
    if (this.isDisabled() || this.isLoading()) {
      crmKillEvent(event);
      return;
    }
    this.actionClicked.emit(this.action);
  }
  handleActionChange(action) {
    if (!action) {
      return;
    }
    const {
      currentValue,
      previousValue
    } = action;
    if (previousValue) {
      this.reload$.next();
    }
    if (currentValue) {
      const {
        isDisabled$,
        isLoading$
      } = currentValue;
      this.handleResolvableValue(this.isDisabled, isDisabled$);
      this.handleResolvableValue(this.isLoading, isLoading$);
    } else {
      this.isDisabled.set(false);
      this.isLoading.set(false);
    }
  }
  handleResolvableValue(signal, resolvable) {
    crmResolveExpression({
      resolvable,
      source: this.data
    }).pipe(takeUntil(this.reload$)).subscribe(value => signal.set(value));
  }
  static {
    this.ɵfac = function CrmActionButtonComponent_Factory(t) {
      return new (t || CrmActionButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmActionButtonComponent,
      selectors: [["crm-action-button"]],
      hostVars: 2,
      hostBindings: function CrmActionButtonComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function CrmActionButtonComponent_click_HostBindingHandler($event) {
            return ctx.handleActionClick($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        action: "action",
        data: "data"
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 3,
      consts: [[3, "crmRenderer"], ["nz-button", "", "crmDisabled", "", 3, "id", "ngClass", "ngStyle", "nzType", "nzDanger", "nzSize", "nzBlock", "isDisabled", "disabledTooltip", "enabledTooltip", "nzLoading"], ["nz-icon", "", 3, "nzType"]],
      template: function CrmActionButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmActionButtonComponent_Conditional_0_Template, 1, 1, "ng-container", 0);
          i0.ɵɵpipe(1, "crmIsRenderItem");
          i0.ɵɵtemplate(2, CrmActionButtonComponent_Conditional_2_Template, 6, 20);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional(0, (tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.action)) ? 0 : 2, tmp_0_0);
        }
      },
      dependencies: [CrmTranslatePipe, CrmRendererDirective, CrmIsRenderItemPipe, NgClass, NgStyle, CrmElementIdPipe, NzButtonComponent, CrmDisabledDirective, NzIconDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmActionButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-action-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, CrmRendererDirective, CrmIsRenderItemPipe, NgClass, NgStyle, CrmElementIdPipe, NzButtonComponent, CrmDisabledDirective, NzTooltipDirective, NzIconDirective],
      template: "@if (action | crmIsRenderItem; as item) {\r\n  <ng-container [crmRenderer]=\"item\"></ng-container>\r\n} @else {\r\n  <button\r\n    nz-button\r\n    crmDisabled\r\n    [id]=\"action.id | crmElementId: 'action-button'\"\r\n    [ngClass]=\"action.className\"\r\n    [ngStyle]=\"action.style\"\r\n    [nzType]=\"action.type ?? 'default'\"\r\n    [nzDanger]=\"action.danger\"\r\n    [nzSize]=\"action.size ?? 'large'\"\r\n    [nzBlock]=\"action.block\"\r\n    [isDisabled]=\"isDisabled()\"\r\n    [disabledTooltip]=\"action.disabledTooltip | crmTranslate\"\r\n    [enabledTooltip]=\"action.tooltip | crmTranslate\"\r\n    [nzLoading]=\"isLoading()\"\r\n  >\r\n    @if (action.icon) {\r\n      <i nz-icon [nzType]=\"action.icon\"></i>\r\n    }\r\n\r\n    @if (action.title) {\r\n      <span>{{ action.title | crmTranslate }}</span>\r\n    }\r\n  </button>\r\n}\r\n"
    }]
  }], null, {
    action: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    actionClicked: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    handleActionClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
const crmResolveAction = (action, data) => {
  if (!action.action) {
    return false;
  }
  if (data != null) {
    action.action(data);
  } else {
    action.action();
  }
  return true;
};
class CrmDropdownButtonComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.actionClicked = new EventEmitter();
    this.hostClass = 'crm-dropdown-button';
    this.isDisabledSignal = signal(false);
    this.reload$ = new Subject();
  }
  ngOnInit() {
    if (!this.moreIcon && !this.moreText) {
      this.moreIcon = 'ellipsis';
    }
  }
  ngOnChanges(changes) {
    this.handleIsDisabledChange(changes['isDisabled']);
  }
  handleDropdownClick(ev) {
    crmKillEvent(ev);
  }
  handleActionClick(action) {
    if (this.actionClicked.observed) {
      this.actionClicked.emit(action);
      return;
    }
    crmResolveAction(action, this.data);
  }
  handleIsDisabledChange(isDisabled) {
    if (!isDisabled) {
      return;
    }
    const {
      currentValue,
      previousValue
    } = isDisabled;
    if (previousValue) {
      this.reload$.next();
    }
    if (currentValue) {
      const resolvable = currentValue;
      crmResolveExpression({
        resolvable,
        source: this.data
      }).pipe(takeUntil(race(this.reload$, this.destroyed$))).subscribe(value => this.isDisabledSignal.set(value));
    } else {
      this.isDisabledSignal.set(false);
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmDropdownButtonComponent_BaseFactory;
      return function CrmDropdownButtonComponent_Factory(t) {
        return (ɵCrmDropdownButtonComponent_BaseFactory || (ɵCrmDropdownButtonComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmDropdownButtonComponent)))(t || CrmDropdownButtonComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmDropdownButtonComponent,
      selectors: [["crm-dropdown-button"]],
      hostVars: 2,
      hostBindings: function CrmDropdownButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        actions: "actions",
        id: "id",
        moreText: "moreText",
        moreIcon: "moreIcon",
        moreIconRotate: "moreIconRotate",
        isDisabled: "isDisabled",
        disabledTooltip: "disabledTooltip",
        type: "type",
        size: "size",
        dropdownTrigger: "dropdownTrigger",
        buttonStyle: "buttonStyle",
        data: "data"
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 9,
      vars: 13,
      consts: [["menu1", "nzDropdownMenu"], ["nz-button", "", "nz-dropdown", "", "crmDisabled", "", "nzPlacement", "bottomRight", 3, "click", "id", "nzType", "nzSize", "ngStyle", "nzTrigger", "nzDropdownMenu", "isDisabled", "disabledTooltip"], ["nz-icon", "", 3, "nzType", "nzRotate"], ["nz-menu", "", 1, "crm-dropdown-button__menu"], ["nz-menu-item", "", 1, "crm-dropdown-button__menu__item", 3, "id"], [3, "actionClicked", "data", "action"]],
      template: function CrmDropdownButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "button", 1);
          i0.ɵɵpipe(1, "crmTranslate");
          i0.ɵɵlistener("click", function CrmDropdownButtonComponent_Template_button_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleDropdownClick($event));
          });
          i0.ɵɵtemplate(2, CrmDropdownButtonComponent_Conditional_2_Template, 1, 2, "i", 2)(3, CrmDropdownButtonComponent_Conditional_3_Template, 2, 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "nz-dropdown-menu", null, 0)(6, "ul", 3);
          i0.ɵɵrepeaterCreate(7, CrmDropdownButtonComponent_For_8_Template, 4, 10, "li", 4, _forTrack0);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_2_0;
          let tmp_3_0;
          let tmp_4_0;
          let tmp_5_0;
          const menu1_r5 = i0.ɵɵreference(5);
          i0.ɵɵproperty("id", ctx.id)("nzType", (tmp_2_0 = ctx.type) !== null && tmp_2_0 !== undefined ? tmp_2_0 : "default")("nzSize", (tmp_3_0 = ctx.size) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "large")("ngStyle", (tmp_4_0 = ctx.buttonStyle) !== null && tmp_4_0 !== undefined ? tmp_4_0 : i0.ɵɵpureFunction0(12, _c0))("nzTrigger", (tmp_5_0 = ctx.dropdownTrigger) !== null && tmp_5_0 !== undefined ? tmp_5_0 : "click")("nzDropdownMenu", menu1_r5)("isDisabled", ctx.isDisabledSignal())("disabledTooltip", i0.ɵɵpipeBind1(1, 10, ctx.disabledTooltip));
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(2, ctx.moreIcon ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(3, ctx.moreText ? 3 : -1);
          i0.ɵɵadvance(4);
          i0.ɵɵrepeater(ctx.actions);
        }
      },
      dependencies: [CrmActionButtonComponent, CrmTranslatePipe, NzButtonComponent, NgStyle, NzDropDownDirective, CrmDisabledDirective, NzIconDirective, NzDropdownMenuComponent, CrmElementIdPipe, NzMenuDirective, NzMenuItemComponent, CrmMergeObjectsPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmDropdownButtonComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-dropdown-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmActionButtonComponent, CrmTranslatePipe, NzButtonComponent, NgStyle, NzDropDownDirective, CrmDisabledDirective, NzIconDirective, NzDropdownMenuComponent, CrmElementIdPipe, NzMenuDirective, NzMenuItemComponent, CrmMergeObjectsPipe],
      template: "<button\r\n  [id]=\"id\"\r\n  nz-button\r\n  nz-dropdown\r\n  crmDisabled\r\n  [nzType]=\"type ?? 'default'\"\r\n  [nzSize]=\"size ?? 'large'\"\r\n  [ngStyle]=\"buttonStyle ?? {}\"\r\n  [nzTrigger]=\"dropdownTrigger ?? 'click'\"\r\n  [nzDropdownMenu]=\"menu1\"\r\n  nzPlacement=\"bottomRight\"\r\n  [isDisabled]=\"isDisabledSignal()\"\r\n  [disabledTooltip]=\"disabledTooltip | crmTranslate\"\r\n  (click)=\"handleDropdownClick($event)\"\r\n>\r\n  @if (moreIcon) {\r\n    <i nz-icon [nzType]=\"moreIcon\" [nzRotate]=\"moreIconRotate ?? 0\"></i>\r\n  }\r\n\r\n  @if (moreText) {\r\n    {{ moreText | crmTranslate }}\r\n  }\r\n</button>\r\n\r\n<nz-dropdown-menu #menu1=\"nzDropdownMenu\">\r\n  <ul nz-menu class=\"crm-dropdown-button__menu\">\r\n    @for (action of actions; track action.id) {\r\n      <li\r\n        nz-menu-item\r\n        class=\"crm-dropdown-button__menu__item\"\r\n        [id]=\"action.id | crmElementId: 'detailHeader_actions'\"\r\n      >\r\n        <crm-action-button\r\n          [data]=\"data\"\r\n          [action]=\"{ type: 'link', size: 'small' } | crmMergeObjects: action\"\r\n          (actionClicked)=\"handleActionClick(action)\"\r\n        ></crm-action-button>\r\n      </li>\r\n    }\r\n  </ul>\r\n</nz-dropdown-menu>\r\n"
    }]
  }], null, {
    actions: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    moreText: [{
      type: Input
    }],
    moreIcon: [{
      type: Input
    }],
    moreIconRotate: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    disabledTooltip: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    dropdownTrigger: [{
      type: Input
    }],
    buttonStyle: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    actionClicked: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Component to show HeaderActions as ActionButtonComponents with a simple logic of displaying only selected
 * count of a buttons for an actions and hiding rest in a dropdown menu.
 */
class CrmActionButtonsComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    /**
     * Output emitting in case click event was fired on action button
     */
    this.actionClicked = new EventEmitter();
    this.hostClass = 'crm-action-buttons';
    this.inlineActions = signal([]);
    this.dropdownActions = signal([]);
    this.reload$ = new Subject();
  }
  ngOnInit() {
    this.dropdownConfig ??= {};
    this.dropdownConfig.data ??= this.data;
  }
  ngOnChanges() {
    this.separateActionsCount ??= 2;
    this.handleActionsChange();
  }
  /**
   * Handler for action click
   *
   * @param action
   */
  handleActionClick(action) {
    if (crmResolveAction(action, this.data)) {
      return;
    }
    this.actionClicked.emit(action);
  }
  handleActionsChange() {
    this.reload$.next();
    if (!this.actions?.length) {
      this.inlineActions.set([]);
      this.dropdownActions.set([]);
      return;
    }
    combineLatest(this.actions.map(action => this.resolveActionIsShown(action))).pipe(map(actions => compact(actions)), takeUntil(race(this.reload$, this.destroyed$))).subscribe(shownActions => {
      const actions = shownActions.slice(0, this.separateActionsCount);
      const dropdownActions = shownActions.slice(this.separateActionsCount);
      this.inlineActions.set(actions);
      this.dropdownActions.set(dropdownActions);
    });
  }
  resolveActionIsShown(action) {
    if (action.isShown$ == null) {
      return of(action);
    }
    return crmResolveExpression({
      resolvable: action.isShown$,
      source: this.data
    }).pipe(startWith(false), map(isShown => isShown ? action : undefined));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmActionButtonsComponent_BaseFactory;
      return function CrmActionButtonsComponent_Factory(t) {
        return (ɵCrmActionButtonsComponent_BaseFactory || (ɵCrmActionButtonsComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmActionButtonsComponent)))(t || CrmActionButtonsComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmActionButtonsComponent,
      selectors: [["crm-action-buttons"]],
      hostVars: 2,
      hostBindings: function CrmActionButtonsComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        actions: "actions",
        separateActionsCount: "separateActionsCount",
        data: "data",
        dropdownConfig: "dropdownConfig"
      },
      outputs: {
        actionClicked: "actionClicked"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 1,
      consts: [[3, "ngStyle", "action", "data"], [3, "id", "actions", "moreText", "moreIcon", "moreIconRotate", "isDisabled", "disabledTooltip", "type", "size", "dropdownTrigger", "buttonStyle", "data"], [3, "actionClicked", "ngStyle", "action", "data"], [3, "actionClicked", "id", "actions", "moreText", "moreIcon", "moreIconRotate", "isDisabled", "disabledTooltip", "type", "size", "dropdownTrigger", "buttonStyle", "data"]],
      template: function CrmActionButtonsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵrepeaterCreate(0, CrmActionButtonsComponent_For_1_Template, 1, 3, "crm-action-button", 0, _forTrack0);
          i0.ɵɵtemplate(2, CrmActionButtonsComponent_Conditional_2_Template, 2, 15, "crm-dropdown-button", 1);
        }
        if (rf & 2) {
          i0.ɵɵrepeater(ctx.inlineActions());
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(2, ctx.dropdownActions().length ? 2 : -1);
        }
      },
      dependencies: [CrmActionButtonComponent, CrmDropdownButtonComponent, NgStyle, CrmElementIdPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmActionButtonsComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-action-buttons',
      imports: [CrmActionButtonComponent, CrmDropdownButtonComponent, NgStyle, CrmElementIdPipe],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@for (action of inlineActions(); track action.id) {\r\n  <crm-action-button\r\n    [ngStyle]=\"action.actionStyle\"\r\n    [action]=\"action\"\r\n    [data]=\"data\"\r\n    (actionClicked)=\"handleActionClick($event)\"\r\n  ></crm-action-button>\r\n}\r\n\r\n@if (dropdownActions().length) {\r\n  <crm-dropdown-button\r\n    [id]=\"'more_dropdown' | crmElementId: 'action-buttons'\"\r\n    [actions]=\"dropdownActions()\"\r\n    [moreText]=\"dropdownConfig?.moreText\"\r\n    [moreIcon]=\"dropdownConfig?.moreIcon\"\r\n    [moreIconRotate]=\"dropdownConfig?.moreIconRotate\"\r\n    [isDisabled]=\"dropdownConfig?.isDisabled\"\r\n    [disabledTooltip]=\"dropdownConfig?.disabledTooltip\"\r\n    [type]=\"dropdownConfig?.type\"\r\n    [size]=\"dropdownConfig?.size\"\r\n    [dropdownTrigger]=\"dropdownConfig?.dropdownTrigger\"\r\n    [buttonStyle]=\"dropdownConfig?.buttonStyle\"\r\n    [data]=\"dropdownConfig?.data ?? data\"\r\n    (actionClicked)=\"handleActionClick($event)\"\r\n  ></crm-dropdown-button>\r\n}\r\n"
    }]
  }], null, {
    actions: [{
      type: Input
    }],
    separateActionsCount: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    dropdownConfig: [{
      type: Input
    }],
    actionClicked: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmAsActionButton {
  transform(value) {
    return value;
  }
  static {
    this.ɵfac = function CrmAsActionButton_Factory(t) {
      return new (t || CrmAsActionButton)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "crmAsActionButton",
      type: CrmAsActionButton,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmAsActionButton, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'crmAsActionButton'
    }]
  }], null, null);
})();

/**
 * @deprecated
 */
class CrmButtonsTypeCheckPipeModule {
  static {
    this.ɵfac = function CrmButtonsTypeCheckPipeModule_Factory(t) {
      return new (t || CrmButtonsTypeCheckPipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CrmButtonsTypeCheckPipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmButtonsTypeCheckPipeModule, [{
    type: NgModule,
    args: [{
      imports: [CrmAsActionButton],
      exports: [CrmAsActionButton]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmActionButtonComponent, CrmActionButtonsComponent, CrmAsActionButton, CrmButtonsTypeCheckPipeModule, CrmDropdownButtonComponent, crmResolveAction };
